import { FiBell } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';

import permissions from '~/utils/permissions';

import { Section } from './types';

const notificationsSection: Section = {
  id: uuid(),
  title: 'notifications.title',
  rootPath: 'notifications',
  type: 'default',
  icon: FiBell,
  permissions: permissions.notifications.view,
  pages: [
    {
      id: uuid(),
      title: 'notifications.dashboard.title',
      rootPath: '/dashboard',
      path: '/notifications/dashboard',
    },
    {
      id: uuid(),
      title: 'notifications.list.title',
      rootPath: '/notifications',
      path: '/notifications',
    },
  ],
};

export default notificationsSection;
