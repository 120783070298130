export default {
  modal_options: {
    title: 'Nova Aula - Passo 1: Tipo de Aula',
    subtitle: 'Selecione um tipo de node:',

    options: {
      link: 'Link',
      material: 'Material',
      video: 'Vídeo',
    },
  },

  modal_step_one: {
    title: 'Nova Aula - Passo 2: Configuração',
    edit_title: 'Edição de Aula: Configuração da Aula',
    subtitle_one: 'Dados da Aula',
    subtitle_two: 'Configuração de vídeo',
    subtitle_link: 'Configurações de link',
  },

  modal_step_two: {
    title: 'Novo Node - Passo 3: Extras',
    edit_title: 'Edição de Aula: Extras',
    subtitle: 'Link de conteúdo (Opcional)',
  },

  fields: {
    title: 'Título da Aula',
    slug: 'Slug da Aula',
    tags: 'Tags da Aula',
    release_at: 'Data da Liberação',
    journey: 'Jornada',
    description: 'Descrição da aula (markdown)',
    platform: 'Plataforma de vídeo',
    video_id: 'ID do vídeo',
    link: 'Link',
    files: 'Downloads da aula (Opcional)',
  },

  placeholder: {
    title: 'Digite o título da aula',
    slug: 'Gerado automaticamente',
    no_options_tag: 'Crie uma tag',
    tags: 'Ex: #Tag1 #Tag2',
    release_at: 'XX/XX/XXXX',
    journey: 'Selecione uma jornada',
    video_id: 'Ex: 34342342',
    link: 'Cole aqui um link para sua aula',
    files: 'Arraste um arquivo ou clique para carregar',
  },

  buttons: {
    back: 'Voltar',
    cancel: 'Cancelar',
    submit: 'Cadastrar Aula',
    edit: 'Editar Aula',
    next_step: 'Próximo passo',
  },

  validations: {
    title: 'O título é obrigatório',
    tags: 'Tag é obrigatório',
    release_at: 'Data de liberação é obrigatório',
    description: 'Descrição é obrigatório',
    platform: 'Plataforma de vídeo é obrigatório',
    video_id: 'ID do vídeo é obrigatório',
    link: 'Link é obrigatório',
    wrong_link_format: 'Insira um link valido.',
    resource: 'Material é obrigatório',
  },

  edit: {
    title: 'Salvar alterações',

    confirm: {
      title: 'Deseja realmente modificar essa aula?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Aula editada!',
        description: 'A aula {lessonTitle} foi editada com sucesso',
      },
    },
  },

  new: {
    title: 'Nova Aula',

    confirm: {
      title: 'Deseja realmente criar essa aula?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Aula criada!',
        description: 'A aula {lessonTitle} foi criada com sucesso',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir essa aula?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Aula excluída!',
      },
    },
  },

  close_modal: {
    title: 'Cancelar alterações',
    description:
      'Tem certeza que deseja voltar? Os dados não salvos serão perdidos.',
  },
};
