import forgot from './forgot';
import signin from './signin';

export default {
  title: 'Controle da Plataforma Rocketseat.',
  version: 'Versão {version} © Rocketseat {year}',

  signin,
  forgot,
};
