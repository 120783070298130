export default {
  title: 'Jornadas',

  new: 'Nova Jornada',
  search: 'Buscar jornada',

  empty: 'Nenhuma jornada foi encontrada',

  table_columns: {
    title: 'Jornada',
  },

  options: {
    edit: 'Editar Jornada',
    remove: 'Excluir Jornada',
  },

  remove: {
    confirm: {
      title: 'Deseja realmente excluir essa jornada?',
      description: 'Essa ação não poderá ser desfeita.',

      confirm: 'Sim, quero excluí-la',
      cancel: 'Cancelar',

      success: {
        title: 'Jornada excluída!',
        description: 'A jornada {journeyTitle} foi excluida com sucesso',
      },
    },
  },
};
