export default {
  title: {
    prefix: 'Jornadas / ',
  },

  header_title: 'Configurações da Jornada',

  header_buttons: {
    show_teams: 'Exibição por times',
    add_class: 'Criar aula',
    add_node: 'Criar node',
  },

  empty: `Você ainda não criou nenhum node ou aula...`,
};
