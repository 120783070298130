import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const UsersList = lazy(() => import('~/pages/Registrations/Users/UsersList'));
const UsersForm = lazy(() => import('~/pages/Registrations/Users/UsersForm'));
const UserMainForm = lazy(
  () => import('~/pages/Registrations/Users/UsersForm/sessions/MainForm'),
);
const UserPermissionsForm = lazy(
  () =>
    import('~/pages/Registrations/Users/UsersForm/sessions/UsersPermissions'),
);
const UsersDetails = lazy(
  () => import('~/pages/Registrations/Users/UsersDetails'),
);
const UserPersonalDetails = lazy(
  () => import('~/pages/Registrations/Users/UsersDetails/sessions/Personal'),
);
const UserAccessHistory = lazy(
  () =>
    import('~/pages/Registrations/Users/UsersDetails/sessions/AccessHistory'),
);
const UserEmailsHistory = lazy(
  () =>
    import('~/pages/Registrations/Users/UsersDetails/sessions/EmailsHistory'),
);
const UserPurchases = lazy(
  () => import('~/pages/Registrations/Users/UsersDetails/sessions/Purchases'),
);

const registrationsUsersRoutes = (
  <Route path="/users" permissions={[permissions.users.view]}>
    <Route path="/" element={<UsersList />} />

    <Route
      path="/create"
      element={<UsersForm />}
      permissions={[permissions.users.edit]}
    >
      <Route path="/" element={<UserMainForm />} />
      <Route
        path="/permissions"
        element={<UserPermissionsForm />}
        permissions={[permissions.users.edit_roles]}
      />
    </Route>

    <Route
      path="/:id/edit"
      element={<UsersForm />}
      permissions={[permissions.users.edit]}
    >
      <Route path="/" element={<UserMainForm />} />
      <Route
        path="/permissions"
        element={<UserPermissionsForm />}
        permissions={[permissions.users.edit_roles]}
      />

      <Route
        path="/purchases"
        permissions={[permissions.purchases.edit]}
        element={<UserPurchases />}
      />
    </Route>

    <Route path="/:id" element={<UsersDetails />}>
      <Route path="/" element={<UserPersonalDetails />} />

      <Route path="/access_history" element={<UserAccessHistory />} />

      <Route path="/emails_history" element={<UserEmailsHistory />} />

      <Route
        path="/purchases"
        permissions={[permissions.purchases.view]}
        element={<UserPurchases />}
      />
    </Route>
  </Route>
);

export default registrationsUsersRoutes;
