import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import usePersistedTheme from '~/hooks/usePersistedTheme';
import { dark, light } from '~/styles/themes';

import ApolloClientProvider from './apolloClient';
import AuthProvider from './auth';
import ContextProvider from './contextProvider';
import IntlProvider from './intl';
import RouteProvider from './route';

interface Props {
  children: React.ReactNode;
}

function AppProvider({ children }: Props) {
  const [theme] = usePersistedTheme('dark');

  const currentTheme = useMemo(() => (theme === 'dark' ? dark : light), [
    theme,
  ]);

  const providers = useMemo(
    () => [
      <ThemeProvider theme={currentTheme} />,
      <IntlProvider />,
      <BrowserRouter />,
      <AuthProvider />,
      <RouteProvider />,
      <ApolloClientProvider />,
    ],
    [currentTheme],
  );

  return (
    <ContextProvider contextProviders={providers}>{children}</ContextProvider>
  );
}

export default AppProvider;
