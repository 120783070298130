import { useContext } from 'react';

import { AuthContext, AuthContextType } from '~/context/auth';

function useAuth(): AuthContextType {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export default useAuth;
