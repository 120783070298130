export default {
  title: {
    prefix: 'Denúncia ',

    report_number: '#{reportNumber}',
  },

  button: {
    approve: 'Aprovar',
    reject: 'Rejeitar',
  },

  modal: {
    placeholder: 'Digite aqui sua justificativa...',

    approved: {
      title: 'Aprovar denúncia',
      subtitle:
        'Para aprovar essa denúncia informe a justificativa. Descreva o motivo da aprovação.',

      confirm: 'Aprovar',
      cancel: 'Cancelar',

      success: {
        title: 'Denúncia aprovada!',
        description: 'A denúncia foi aprovada com sucesso.',
      },
    },

    rejected: {
      title: 'Rejeitar denúncia',
      subtitle:
        'Para rejeitar essa denúncia informe a justificativa. Descreva o motivo da rejeição.',

      confirm: 'Rejeitar',
      cancel: 'Cancelar',

      success: {
        title: 'Denúncia rejeitada!',
        description: 'A denúncia foi rejeitada com sucesso.',
      },
    },
  },

  tooltips: {
    report_by: 'Denúncias realizadas sobre ',
    reported: 'Denúncias recebidas aprovadas',
  },

  report_details: {
    title: 'Detalhes da denúncia',

    report_date: 'Data da denúncia',
    report_by: 'Quem denunciou',
    reported: 'Quem foi denunciado',
    zone: 'Área',
    status: 'Status',
    type: 'Tipo',

    description: 'Descrição',
    additional_text: 'Texto adicional da denúncia',

    not_specified: 'Não especificado',
  },

  reported_content: {
    title: 'Conteúdo denunciado',
    subtitle: '(Conteúdo originalmente publicado)',
  },

  related_reports: {
    title: 'Outras denúncias relacionadas',
    subtitle:
      '(Ao aprovar esta denúncia, você também aprovará todas as relacionadas)',

    report_date: 'Data da denúncia',
    report_by: 'Quem denunciou',
  },

  rejected_details: {
    title: 'Detalhes da rejeição',

    rejected_date: 'Data/Hora',
    rejected_by: 'Quem rejeitou',

    justification: 'Justificativa da rejeição',
  },

  approved_details: {
    title: 'Detalhes da aprovação',

    approved_date: 'Data/Hora',
    approved_by: 'Quem aprovou',

    justification: 'Justificativa da aprovação',
  },
};
