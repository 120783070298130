import React from 'react';

import Route from '../Route';
import creatorsRoutes from './creators.routes';
import lessonsRoutes from './lessons.routes';

const expertsRoutes = (
  <Route path="/experts">
    {creatorsRoutes}
    {lessonsRoutes}
  </Route>
);

export default expertsRoutes;
