export default {
  title: 'light',

  colors: {
    primary: '#8257E6',
    greenseat: '#33FF99',
    green: '#04D361',
    greenlow: '#11251C',
    errolow: '#3B2228',
    purplelow: '#2D264A',
    red: '#E83F5B',
    orange: '#FD951F',
    white: '#FFFFFF',
    background: '#F0F0F5',
    mainShape: '#FFFFFF',
    shape18: '#E4E4EB',
    shape22: '#F2F2FA',
    shape26: '#f00',
    titles: '#3D3D4D',
    text: '#6C6C80',
    supportText: '#A0A0B3',
    tooltipBackground: '#F0F0F5',
    helperBackground: '#F0F0F5',
    inputsIcons: '#41414D',
    sideBarIcon: '#C9B2FF',
    overlay: '#00000033',
  },
};
