export default {
  title: 'Histórico de acesso',

  empty: 'Nenhum login foi encontrado',

  format: {
    logged_at: "dd',' MMM',' yyyy',' EEEE",
  },

  table_columns: {
    login: 'Login',
    time: 'Horário',
    ip: 'IP',
    where: 'Local',
    devices: 'Dispositivos',
  },

  clear_cache: {
    title: 'Limpar cache',

    confirm: {
      title: 'Deseja limpar o cache do usuário?',
      description:
        'O usuário {userName} terá os dados armazenados em cache apagados.',

      success: {
        title: 'Cache limpa!',
        description:
          'Os dados do usuário {userName} armazenados em cache foram apagados.',
      },
    },
  },

  revoke_access: {
    title: 'Revogar acesso',

    confirm: {
      title: 'Deseja revogar a acesso do usuário?',
      description: 'O usuário {userName} terá a sessão invalidada.',

      success: {
        title: 'Acesso revogado!',
        description: 'A sessão do usuário {userName} foi invalidada.',
      },
    },
  },
};
