import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const CompaniesPreRegistrationsList = lazy(
  () => import('~/pages/Registrations/Companies/PreRegistrationsList'),
);
const CompaniesPreRegistrationsForm = lazy(
  () => import('~/pages/Registrations/Companies/PreRegistrationsForm'),
);

const registrationsCompaniesRoutes = (
  <Route
    path="/companies"
    permissions={[permissions.companies_registrations.view]}
  >
    <Route path="/pre-registrations">
      <Route path="/" element={<CompaniesPreRegistrationsList />} />

      <Route
        path="/create"
        permissions={[permissions.companies_registrations.edit]}
        element={<CompaniesPreRegistrationsForm />}
      />

      <Route
        path="/:id/edit"
        permissions={[permissions.companies_registrations.edit]}
        element={<CompaniesPreRegistrationsForm />}
      />
    </Route>
  </Route>
);

export default registrationsCompaniesRoutes;
