import { cloneElement, ReactElement, ReactNode, memo } from 'react';

interface Props {
  contextProviders: ReactElement[];
  children: ReactNode;
}

function ContextProvider({ contextProviders, children }: Props): any {
  return contextProviders.reduceRight((element, parent) => {
    return cloneElement(parent, { children: element });
  }, children);
}

export default memo(ContextProvider);
