import React from 'react';

import Route from '../Route';
import journeysRoutes from './journeys.routes';
import lessonsRoutes from './lessons.routes';

const coursesRoutes = (
  <Route path="/courses">
    {lessonsRoutes}
    {journeysRoutes}
  </Route>
);

export default coursesRoutes;
