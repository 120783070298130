import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const LessonsList = lazy(() => import('~/pages/Experts/Lessons/LessonsList'));
const LessonsStatistics = lazy(
  () => import('~/pages/Experts/Lessons/LessonsStatistics'),
);
const LessonEdit = lazy(
  () => import('~/pages/Experts/Lessons/LessonsList/EditLesson'),
);

const LessonEditStepOne = lazy(
  () =>
    import('~/pages/Experts/Lessons/LessonsList/EditLesson/sessions/StepOne'),
);
const LessonEditStepTwo = lazy(
  () =>
    import('~/pages/Experts/Lessons/LessonsList/EditLesson/sessions/StepTwo'),
);
const LessonEditStepThree = lazy(
  () =>
    import('~/pages/Experts/Lessons/LessonsList/EditLesson/sessions/StepThree'),
);
const LessonEditStepFour = lazy(
  () =>
    import('~/pages/Experts/Lessons/LessonsList/EditLesson/sessions/StepFour'),
);

const lessonsRoutes = (
  <Route path="/lessons">
    <Route
      path="/"
      permissions={[permissions.lessons.view]}
      element={<LessonsList />}
    />

    <Route
      path="/:id"
      permissions={[permissions.lessons.view]}
      element={<LessonsStatistics />}
    />

    <Route
      path="/:id/edit"
      element={<LessonEdit />}
      permissions={[permissions.lessons.edit]}
    >
      <Route path="/" element={<LessonEditStepOne />} />
      <Route path="/lesson-material" element={<LessonEditStepTwo />} />
      <Route path="/thumbnail" element={<LessonEditStepThree />} />
      <Route path="/sessions" element={<LessonEditStepFour />} />
    </Route>
  </Route>
);

export default lessonsRoutes;
