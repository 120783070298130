export default {
  title: {
    prefix: 'Tecnologias / ',

    new: 'Nova tecnologia',
    edit: 'Editar {tecTitle}',
  },

  edit: {
    title: 'Salvar alterações',

    confirm: {
      title: 'Deseja realmente modificar essa Tecnologia?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Tecnologia editada!',
        description: 'A tecnologia {tecTitle} foi editada com sucesso',
      },
    },
  },

  new: {
    title: 'Nova tecnologia',

    confirm: {
      title: 'Deseja realmente criar essa tecnologia?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Tecnologia criada!',
        description: 'A tecnologia {tecTitle} foi criada com sucesso',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir essa tecnologia?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Tecnologia excluída!',
      },
    },
  },

  fields: {
    title: 'Nome',
    image: 'Imagem',
  },

  placeholder: {
    title: 'Nome da tecnologia',
    image: 'Clique para enviar uma imagem',
  },
};
