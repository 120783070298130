import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from './Route';

const ReportsList = lazy(() => import('~/pages/Reports/ReportsList'));
const ReportsDetails = lazy(() => import('~/pages/Reports/ReportsDetails'));

const reportsRoutes = (
  <Route path="/reports" permissions={[permissions.reports.view]}>
    <Route path="/" element={<ReportsList />} />

    <Route
      path="/:id"
      permissions={[permissions.reports.view]}
      element={<ReportsDetails />}
    />
  </Route>
);

export default reportsRoutes;
