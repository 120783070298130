export default {
  title: {
    prefix: 'Novo creator / ',

    invite: 'Envio de convite',
  },

  empty_error: 'Insira um endereço de e-mail válido.',
  invalid_email: '"{email}" não é um e-mail válido e não foi adicionado',
  error: 'Ops! Parece que algo deu errado!',

  is_creator: 'Criador de conteúdo',

  verify: 'Verificar e-mail',
  tooltip_text:
    'Nenhum usuário encontrado em nossa base. Um link de pré-cadastro será enviado ao e-mail escolhido.',
  member_since: 'Membro desde',

  new: {
    title: 'Adicionar creator',

    confirm: {
      title: 'Deseja realmente adicionar as permissões de criador de conteúdo?',

      confirm: 'Sim, confirmar',
      cancel: 'Cancelar',

      success: {
        title: 'Permissões adicionadas com sucesso!',
        description: 'Os usuários se tornaram criadores de conteúdo',
      },

      integration: {
        description:
          'As permissões e o acesso ao ExpertsClub foram adicionados com sucesso, porém um ou mais e-mails falharam durante a integração com o Convert Kit: {emails}',
      },
    },
  },

  placeholder: {
    email: 'Adicione e-mails separados por vírgula',
  },
};
