import axios, { AxiosInstance, AxiosResponse } from 'axios';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';

interface Request {
  url: string;
  data: any;
  id?: string;
}

type SignOut = () => void;
interface APIInstance extends AxiosInstance {
  postOrPut: (request: Request, config?: any) => Promise<AxiosResponse>;
  registerInterceptTokenManager: (signOut: SignOut) => void;
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
}) as APIInstance;

api.postOrPut = ({ url, id, data }, config = {}) => {
  const method = id ? 'put' : 'post';
  const apiUrl = id ? `${url}/${id}` : url;

  return api[method](apiUrl, data, config);
};

interface PromiseType {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}

interface ProcessQueueParams {
  error: Error | null;
  token: string | null;
}

let isRefreshing = false;
let failedQueue: Array<PromiseType> = [];

const processQueue = ({ error, token = null }: ProcessQueueParams): void => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.registerInterceptTokenManager = signOut => {
  api.interceptors.response.use(
    response => response,
    async err => {
      if (!err.response) {
        toast.error(intl.get('common.errors.server_error'));
      }

      const refreshToken = localStorage.getItem('@Shuttle:refresh_token');
      const originalRequest = err.config;

      const needsToRefresh =
        err.response &&
        err.response.status === 401 &&
        typeof err.response.data === 'string' &&
        err.response.data.startsWith('E_JWT_TOKEN_EXPIRED');

      if (needsToRefresh) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers.Authorization = `bearer ${token}`;
              return api(originalRequest);
            })
            .catch(error => error);
        }

        isRefreshing = true;

        return new Promise(async (resolve, reject) => {
          try {
            const { data } = await api.post('/sessions/refresh', {
              refresh_token: refreshToken,
            });

            localStorage.setItem('@Shuttle:token', data.token);
            localStorage.setItem('@Shuttle:refresh_token', data.refreshToken);

            api.defaults.headers.Authorization = `bearer ${data.token}`;
            originalRequest.headers.Authorization =
              api.defaults.headers.Authorization;

            processQueue({ error: null, token: data.token });
            resolve(api(originalRequest));
          } catch (error) {
            processQueue({ error, token: null });
            signOut();
            reject(error);
          } finally {
            isRefreshing = false;
          }
        });
      }

      const invalidToken =
        err.response &&
        err.response.status === 401 &&
        typeof err.response.data === 'string' &&
        err.response.data.startsWith('E_INVALID_JWT_TOKEN:');

      if (invalidToken) {
        toast.error('Sua sessão expirou, faça login novamente.');

        signOut();
      }

      return Promise.reject(err);
    },
  );
};

export default api;
