import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type Response = [string, Dispatch<SetStateAction<string>>];

function usePersistedTheme(defaultTheme: 'light' | 'dark'): Response {
  const [theme, setTheme] = useState(() => {
    const storageValue = localStorage.getItem('@Shuttle:theme');

    if (storageValue) {
      return storageValue;
    }

    return defaultTheme;
  });

  useEffect(() => {
    localStorage.setItem('@Shuttle:theme', theme);
  }, [theme]);

  return [theme, setTheme];
}

export default usePersistedTheme;
