export default {
  button: {
    login: 'Entrar',
    send: 'Enviar',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    retry: 'Tentar novamente',
    exit: 'Sair',
    finish: 'Finalizar',
  },

  input_file: {
    placeholder: 'Arraste uma imagem ou clique para carregar',
    error: 'Ocorreu um erro ao enviar o arquivo',
  },

  input_video: {
    id: 'ID do vídeo',
    lesson_id: 'ID da aula',
    multiple: 'Arraste um ou mais arquivos ou clique para carregar',
    single: 'Arraste um arquivo ou clique para carregar',
    completed: 'Envio completo',
    error: 'Falha no envio',
  },

  select: {
    empty: 'Nenhuma opção',
    loading: 'Buscando...',
    create: 'Criar {value}',

    async: {
      empty: 'Nenhum resultado',
    },
  },

  paginate: {
    of: 'de',
  },

  header: {
    feedback: 'Dar feedback',

    user: {
      image: 'Foto do usuário',
    },
  },

  video: {
    device_not_allowed:
      'Não foi possível carregar o conteúdo. Por favor, acesse a plataforma através do computador.',
  },

  info_card: {
    copy: 'Copiar',
    copied: 'Copiado',
  },

  markdown: {
    uploading: 'Realizando upload',
    error: 'Ocorreu um erro ao enviar a imagem',
    text: 'Escreva seu texto',
    limit: 'Você atingiu o limite de caracteres.',
    loading: 'Carregando...',
  },
};
