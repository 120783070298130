export default {
  title: 'Listagem de Tecnologias',
  new: 'Nova Tecnologia',
  search: 'Buscar tecnologia',

  empty: 'Nenhuma tecnologia foi encontrada',

  table_columns: {
    title: 'Nome',
  },

  options: {
    edit: 'Editar tecnologia',
    remove: 'Excluir tecnologia',
  },
};
