import form from './form';
import list from './list';
import validators from './validators';

export default {
  title: 'Feature flags',

  form,
  list,
  validators,
};
