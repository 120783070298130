import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const ChangelogsList = lazy(
  () => import('~/pages/Registrations/Changelogs/ChangelogsList'),
);
const ChangelogsForm = lazy(
  () => import('~/pages/Registrations/Changelogs/ChangelogsForm'),
);

const registrationsChangelogsRoutes = (
  <Route path="/changelogs" permissions={[permissions.changelogs.view]}>
    <Route path="/" element={<ChangelogsList />} />

    <Route
      path="/create"
      element={<ChangelogsForm />}
      permissions={[permissions.changelogs.edit]}
    />
    <Route
      path="/:id/edit"
      element={<ChangelogsForm />}
      permissions={[permissions.changelogs.edit]}
    />
  </Route>
);

export default registrationsChangelogsRoutes;
