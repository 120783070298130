export default {
  title: 'Principal',

  account: 'Detalhes da conta',
  shipping_address: 'Endereço de entrega',
  billing_address: 'Endereço de cobrança',
  social: 'Redes Sociais',

  address: {
    postal_code: 'CEP',
    street: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
  },
};
