export default {
  new: 'Nova flag',
  search: 'Buscar por título ou chave',
  status_filter: 'Filtrar por status',

  empty: 'Nenhuma feature flag foi encontrada',

  table_columns: {
    key: 'Chave',
  },

  status_options: {
    active: 'Ativa',
    inactive: 'Inativa',
  },

  options: {
    edit: 'Editar',
    delete: 'Excluir',
  },
};
