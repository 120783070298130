import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from './Route';

const ForumsList = lazy(() => import('~/pages/Forums/ForumsList'));
const ForumsForm = lazy(() => import('~/pages/Forums/ForumsForm'));

const forumsRoutes = (
  <Route path="/forums" permissions={[permissions.forums.view]}>
    <Route path="/" element={<ForumsList />} />

    <Route
      path="/create"
      permissions={[permissions.forums.edit]}
      element={<ForumsForm />}
    />

    <Route
      path="/:id/edit"
      permissions={[permissions.forums.edit]}
      element={<ForumsForm />}
    />
  </Route>
);

export default forumsRoutes;
