const zindex = {
  modalOverlay: 1000,

  toast: 1100,

  tooltip: 100,
  tooltipArrowBorder: 90,
  tooltipOverlay: 80,

  headerUserMenu: 200,
  headerUserMenuArrowBorder: 190,
  headerUserMenuOverlay: 180,

  bottomTabMenuArrowBorder: 800,
  bottomTab: 790,
  bottomTabMenu: 750,
  bottomTabOverlay: 700,
};

export default zindex;
