export default {
  title: 'Creators',

  new: 'Novo creator',
  search: 'Buscar creator',

  empty: 'Nenhum creator foi encontrado',

  table_columns: {
    name: 'Nome do creator',
    email: 'Email do creator',
    invited_at: 'Convidado em',
  },

  options: {
    remove: 'Remover permissões',
  },

  remove: {
    confirm: {
      title: 'Deseja realmente remover as permissões?',
      description: 'As permissões de criador de conteúdo serão removidas',

      confirm: 'Sim, quero remover',
      cancel: 'Cancelar',

      success: {
        title: 'Permissões removidas!',
        description: '"{creatorName}" deixou de ser um criador de conteúdo',
      },

      integration: {
        description:
          'As permissões e o acesso ao ExpertsClub foram removidos com sucesso, porém houve uma falha ao remover a integração com o Convert Kit do email: {email}',
      },
    },
  },
};
