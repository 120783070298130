export default {
  title: 'Principal',

  details: 'Detalhes do time',
  auto_enrollment: 'Auto inscrição',
  enroll_everyone: 'Inscrever todos os usuários',

  members: 'Membros',
  journeys: 'Jornadas',
};
