import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from './Route';

const Dashboard = lazy(() => import('~/pages/Settings/Dashboard'));
const Cache = lazy(() => import('~/pages/Settings/Cache'));

const settingsRoutes = (
  <Route path="/settings">
    <Route path="/dashboard" element={<Dashboard />} />

    <Route
      path="/cache"
      permissions={[permissions.users.edit]}
      element={<Cache />}
    />
  </Route>
);

export default settingsRoutes;
