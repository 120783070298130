import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const CreatorsList = lazy(
  () => import('~/pages/Experts/Creators/CreatorsList'),
);
const CreatorsForm = lazy(
  () => import('~/pages/Experts/Creators/CreatorsForm'),
);

const creatorsRoutes = (
  <Route path="/creators" permissions={[permissions.users.view]}>
    <Route path="/" element={<CreatorsList />} />

    <Route
      path="/create"
      permissions={[permissions.users.edit]}
      element={<CreatorsForm />}
    />
  </Route>
);

export default creatorsRoutes;
