export default {
  new: 'Novo grupo',
  search: 'Buscar grupos',

  empty: 'Nenhum grupo foi encontrado',

  options: {
    edit: 'Editar grupo',
    delete: 'Excluir grupo',
  },
};
