import dashboard from './dashboard';
import details from './details';
import list from './list';
import modals from './modals';

export default {
  title: 'Gestão',

  status: {
    approved: 'Aprovada',
    rejected: 'Rejeitada',
    pending: 'Pendente',
  },

  format_zone: {
    topic: 'Tópico',
    answer: 'Pergunta',
    comment: 'Comentário',
  },

  list,
  dashboard,
  details,
  modals,
};
