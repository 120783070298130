export default {
  title: 'Submissões',

  empty: 'Nenhuma submissão encontrada',

  status: {
    running: 'Executando',
    success: 'Finalizado',
    failed: 'Falhou',
    blocked: 'Bloqueado',
  },

  options: {
    block: 'Bloquear',
    unblock: 'Desbloquear',
    details: 'Ver Detalhes',
    correct: 'Corrigir',
  },

  table_columns: {
    challenge: 'Desafio',
    sender: 'Aluno',
    send_at: 'Data de envio',
    executions: 'Execuções',
  },

  block: {
    title: 'Você deseja bloquear essa submissão?',
    description: 'Você poderá desbloquear essa submissão posteriormente.',
    confirm: 'Sim, quero bloquear',
    cancel: 'Não, quero manter',

    success: {
      title: 'Submissão bloqueada!',
      description: 'Essa submissão não poderá ser corrigida.',
    },
  },

  unblock: {
    title: 'Você deseja desbloquear essa submissão?',
    description: 'Você poderá bloquear essa submissão posteriormente.',
    confirm: 'Sim, quero desbloquear',
    cancel: 'Não, quero manter',

    success: {
      title: 'Submissão desbloqueada!',
      description: 'Essa submissão pode ser corrigida novamente.',
    },
  },

  run_correction: {
    title: 'Você deseja executar novamente a correção desta submissão?',
    description:
      'Os dados da ultima execução sobrescreverão os dados atuais desta submissão.',
    confirm: 'Sim, quero corrigir',
    cancel: 'Nao, quero manter',

    success: {
      title: 'A submissão foi enviada para correção!',
      description: 'O resultado sairá em breve.',
    },
  },
};
