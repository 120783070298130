export default {
  title: {
    prefix: 'Minhas notificações / ',

    new: 'Nova notificação',
    edit: 'Editar notificação',
  },

  edit: {
    title: 'Salvar alterações',

    confirm: {
      title: 'Deseja realmente modificar essa notificação',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Notificação editada!',
        description: 'A notificação foi editada com sucesso',
      },
    },
  },

  new: {
    title: 'Nova Notificação',

    confirm: {
      title: 'Deseja realmente criar essa notificação?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Notificação criada!',
        description: 'A notificação foi criada com sucesso',
      },
    },
  },

  fields: {
    title: {
      label: 'Título da notificação',
      placeholder: 'Ex: Estamos iniciando a nova turma GoStack',
    },

    teams: {
      label: 'Segmentação',
      placeholder: 'Selecione a segmentação',
    },

    scheduled_at: {
      label: 'Agendamento',
      placeholder: 'Agendar horário de envio',
    },

    cta_section: 'Configurações de CTA',

    cta_title: {
      label: 'Título do CTA',
      placeholder: 'Ex: Estamos iniciando a nova turma GoStack',
    },

    link: {
      label: 'Link',
      placeholder: 'Insira a URL',
    },

    description: 'Descrição da notificação',

    checkbox: 'Enviar agora',
  },
};
