import { RiErrorWarningLine } from 'react-icons/ri';
import { v4 as uuid } from 'uuid';

import permissions from '~/utils/permissions';

import { Section } from './types';

const reportsSection: Section = {
  id: uuid(),
  title: 'reports.title',
  rootPath: 'reports',
  type: 'default',
  icon: RiErrorWarningLine,
  permissions: permissions.reports.view,
  pages: [
    {
      id: uuid(),
      title: 'reports.list.title',
      rootPath: '/reports',
      path: '/reports',
    },
  ],
};

export default reportsSection;
