import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const TeamsList = lazy(() => import('~/pages/Registrations/Teams/TeamsList'));
const TeamsDetails = lazy(
  () => import('~/pages/Registrations/Teams/TeamsDetails'),
);
const TeamsForm = lazy(() => import('~/pages/Registrations/Teams/TeamsForm'));

const registrationsTeamsRoutes = (
  <Route path="/teams" permissions={[permissions.teams.view]}>
    <Route path="/" element={<TeamsList />} />

    <Route path="/:id" element={<TeamsDetails />} />

    <Route
      path="/create"
      element={<TeamsForm />}
      permissions={[permissions.teams.edit]}
    />

    <Route
      path="/:id/edit"
      element={<TeamsForm />}
      permissions={[permissions.teams.edit]}
    />
  </Route>
);

export default registrationsTeamsRoutes;
