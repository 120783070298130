import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const PermissionsGroupList = lazy(
  () => import('~/pages/Registrations/PermissionsGroup/PermissionsGroupList'),
);
const PermissionsGroupForm = lazy(
  () => import('~/pages/Registrations/PermissionsGroup/PermissionsGroupForm'),
);

const registrationsPermissionsGroupsRoutes = (
  <Route
    path="/permissions-group"
    permissions={[permissions.permissions_group.view]}
  >
    <Route path="/" element={<PermissionsGroupList />} />

    <Route
      path="/create"
      permissions={[permissions.permissions_group.edit]}
      element={<PermissionsGroupForm />}
    />

    <Route
      path="/:id/edit"
      permissions={[permissions.permissions_group.edit]}
      element={<PermissionsGroupForm />}
    />
  </Route>
);

export default registrationsPermissionsGroupsRoutes;
