import { FiUser } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';

import permissions from '~/utils/permissions';

import { Section } from './types';

const registrationsSection: Section = {
  id: uuid(),
  title: 'registrations.title',
  rootPath: 'registrations',
  type: 'default',
  icon: FiUser,
  pages: [
    {
      id: uuid(),
      title: 'registrations.users.title',
      rootPath: '/users',
      path: '/registrations/users',
      permissions: permissions.users.view,
    },
    {
      id: uuid(),
      title: 'registrations.teams.title',
      rootPath: '/teams',
      path: '/registrations/teams',
      permissions: permissions.teams.view,
    },
    {
      id: uuid(),
      title: 'registrations.sensitive_information.title',
      rootPath: '/sensitive-information',
      path: '/registrations/sensitive-information?answered=false',
      permissions: permissions.sensitive_information.view,
    },
    {
      id: uuid(),
      title: 'registrations.changelogs.title',
      rootPath: '/changelogs',
      path: '/registrations/changelogs',
      permissions: permissions.changelogs.view,
    },
    {
      id: uuid(),
      title: 'registrations.permissions_group.title',
      rootPath: '/permissions-group',
      path: '/registrations/permissions-group',
      permissions: permissions.permissions_group.view,
    },
    {
      id: uuid(),
      title: 'registrations.technologies.title',
      rootPath: '/technologies',
      path: '/registrations/technologies',
    },
    {
      id: uuid(),
      title: 'registrations.companies.pre_registrations.title',
      rootPath: '/companies/pre-registrations',
      path: '/registrations/companies/pre-registrations',
      permissions: permissions.companies_registrations.view,
    },
    {
      id: uuid(),
      title: 'registrations.feature_flags.title',
      rootPath: '/feature_flags',
      path: '/registrations/feature_flags',
      permissions: permissions.feature_flags.view,
    },
  ],
};

export default registrationsSection;
