/* eslint-disable react/destructuring-assignment */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';

export const getApolloClient = () => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        const messageText = message ?? 'Erro interno do servidor';

        return toast.error(messageText);
      });
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('@Shuttle:token');
    const refreshToken = localStorage.getItem('@Shuttle:refresh_token');

    const bearerToken = token || refreshToken;

    return {
      headers: {
        ...headers,
        authorization: bearerToken ? `Bearer ${bearerToken}` : '',
      },
    };
  });

  const httpPlutoLink = createHttpLink({
    uri: process.env.REACT_APP_PLUTO_GRAPHQL_URL,
    fetch,
  });

  const cache = new InMemoryCache({});

  return new ApolloClient({
    link: from([errorLink, authLink, httpPlutoLink]),
    cache,
  });
};
