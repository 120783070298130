export default {
  title: 'Compras',

  new: 'Nova compra',
  empty: 'Nenhuma compra encontrada',

  options: {
    edit: 'Editar Compra',
  },

  table_columns: {
    transaction_id: 'ID da transação',
    product: 'Produto',
    purchase_date: 'Data da compra',
  },

  status: {
    refunded: 'Reembolsado',
    canceled: 'Cancelado',
    finished: 'Processado',
  },

  form: {
    title: 'Editar compra',
    transaction: 'Transação',
    product: 'Produto',
    external_transaction: 'Transação Externa',
    payment_engine: 'Engine de pagamento',
    payment_type: 'Tipo de pagamento',
    currency: 'Moeda',
    warranty_date: 'Data de garantia',
    canceled_at: 'Data de cancelamento',
    refunded_at: 'Data de reembolso',

    placeholder: {
      transaction: 'ID da transação',
      product: 'Produto comprado',
      external_transaction: 'ID da transação externa',
      payment_engine: 'Ex: hotmart',
      payment_type: 'Ex: credit_card',
    },
  },

  edit: {
    save: 'Salvar dados',

    confirm: {
      title: 'Deseja realmente editar essa compra?',
      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Compra atualizada!',
        description: 'A compra foi atualiza com sucesso',
      },
    },

    close: {
      title: 'Cancelar alterações',
      description:
        'Tem certeza que deseja voltar? Os dados não salvos serão perdidos.',
    },
  },

  create: {
    save: 'Salvar dados',

    confirm: {
      title: 'Deseja realmente cadastrar essa compra?',
      confirm: 'Sim',
      cancel: 'Não',

      success: {
        title: 'Compra cadastrada!',
        description: 'A compra foi cadastrada com sucesso',
      },
    },

    close: {
      title: 'Cancelar alterações',
      description:
        'Tem certeza que deseja voltar? Os dados não salvos serão perdidos.',
    },
  },
};
