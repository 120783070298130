export default {
  prefix_title: 'Changelogs / ',

  save_draft: 'Salvar rascunho',
  save_changelog: 'Salvar changelog',

  new: {
    title: 'Novo changelog',

    confirm: {
      title: 'Deseja realmente criar esse changelog?',
      description: 'Você irá criar um novo changelog',

      confirm: 'Sim, quero criar',
      cancel: 'Não, quero cancelar',

      success: {
        title: 'Changelog criado!',
      },
    },
  },

  edit: {
    title: 'Editar {changelogTitle}',

    confirm: {
      title: 'Deseja realmente modificar esse changelog?',
      description: 'Você irá editar as informações deste changelog',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Changelog editado!',
      },
    },
  },

  schedule: {
    confirm: {
      title: 'Deseja realmente agendar esse changelog?',
      description: 'Você poderá editar a data enquanto não for publicado',

      confirm: 'Sim, quero agendar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Changelog agendado!',
      },
    },
  },

  draft: {
    confirm: {
      title: 'Deseja realmente criar esse rascunho?',
      description: 'Você poderá edita-lo mais tarde',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Rascunho criado!',
      },
    },
  },

  edit_draft: {
    confirm: {
      title: 'Deseja realmente modificar esse rascunho?',
      description: 'Você irá editar as informações deste rascunho',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Rascunho editado!',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir este changelog?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Changelog excluído!',
      },
    },
  },

  title: {
    placeholder: 'Nome do changelog',
  },

  description: {
    label: 'Conteúdo',
  },

  toggle: {
    label: 'Agendar publicação',
  },

  scheduled_at: {
    placeholder: 'Escolha a data da publicação',
  },

  validate: {
    has_passed_schedule: 'Você não pode agendar uma data que já passou.',
  },
};
