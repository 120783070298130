import { FiSettings } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';

import { Section } from './types';

const settingsSection: Section = {
  id: uuid(),
  title: 'settings.title',
  rootPath: 'settings',
  type: 'settings',
  icon: FiSettings,
  pages: [
    {
      id: uuid(),
      title: 'settings.dashboard.title',
      rootPath: '/dashboard',
      path: '/settings/dashboard',
    },
    {
      id: uuid(),
      title: 'settings.cache.title',
      rootPath: '/cache',
      path: '/settings/cache',
    },
  ],
};

export default settingsSection;
