import { ApolloProvider } from '@apollo/client';
import React from 'react';

import { getApolloClient } from '~/services/apollo';

interface Props {
  children?: React.ReactNode;
}

const ApolloClientProvider = ({ children }: Props) => {
  return <ApolloProvider client={getApolloClient()}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
