import { Location } from 'history';
import React, {
  useMemo,
  createContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCan from '~/hooks/useCan';
import pageSections from '~/routes/sections';
import { Section, Page } from '~/routes/sections/types';

interface Props {
  children?: React.ReactNode;
}

interface LocationState {
  from?: string;
  stack?: boolean;
}

interface CurrentPage extends Page {
  position: number;
}

interface GoBackParams {
  fallback?: string;
  redirect?: boolean;
}

export interface RouteContextType {
  sections: Section[];
  currentSection?: Section;
  currentPage?: CurrentPage;
  currentPath?: string;
  lastPath?: string;
  redirectTo?: string;
  goBack: (params?: GoBackParams) => void;
}

interface PagesReducer {
  pages: Page[];
  currentPage?: CurrentPage;
}

interface SessionsReducer {
  sections: Section[];
  currentSection?: Section;
  currentPage?: CurrentPage;
}

export const DEFAULT_PATH = '/home';

export const RouteContext = createContext<RouteContextType>(
  {} as RouteContextType,
);

interface History {
  value: Array<string>;
}

function RouteProvider({ children }: Props) {
  const history = useRef<History>({ value: [] });

  const can = useCan();
  const location = useLocation() as Location<LocationState>;
  const navigate = useNavigate();

  const redirectTo = useMemo(() => {
    return location.state?.from;
  }, [location.state]);

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    const currentPath = `${location.pathname}${location.search}`;
    history.current.value.push(currentPath);
  }, [location]);

  const goBack = useCallback(
    (params?: GoBackParams) => {
      history.current.value.pop();
      const lastLocation = history.current.value.pop();

      if (!params?.redirect) {
        return;
      }

      if (lastLocation) {
        navigate(lastLocation);
      } else if (params?.fallback) {
        navigate(params.fallback);
      }
    },
    [navigate],
  );

  const { sections, currentSection, currentPage } = useMemo(() => {
    return pageSections.reduce(
      (data, section) => {
        const { pages, currentPage: selectedPage } = section.pages.reduce(
          (response, page) => {
            const isValidPage = !page.permissions || can(page.permissions);

            if (isValidPage) {
              response.pages.push(page);

              const isCurrentPage = location.pathname.includes(page.rootPath);
              if (isCurrentPage && !response.currentPage) {
                response.currentPage = Object.assign(page, {
                  position: response.pages.length - 1,
                });
              }
            }

            return response;
          },
          { pages: [] } as PagesReducer,
        );

        const response = { ...data };

        const isValidSection = section.permissions
          ? can(section.permissions) && !!pages.length
          : !!pages.length;

        if (isValidSection) {
          response.sections.push({ ...section, pages });

          const isCurrentSection = location.pathname.includes(section.rootPath);
          if (isCurrentSection) {
            response.currentSection = { ...section, pages };
          }

          if (!data.currentPage && !!response.currentSection) {
            response.currentPage = selectedPage;
          }
        }

        return response;
      },
      { sections: [] } as SessionsReducer,
    );
  }, [can, location.pathname]);

  const currentPath = `${location.pathname}${location.search}`;
  const lastPath = history.current.value[history.current.value.length - 1];

  return (
    <RouteContext.Provider
      value={{
        sections,
        currentSection,
        currentPath,
        lastPath: lastPath === currentPath ? undefined : lastPath,
        currentPage,
        redirectTo,
        goBack,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
}

export default RouteProvider;
