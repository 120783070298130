export default {
  details: 'Detalhes da alteração',
  confirm_changes: 'Confirmar alteração',
  approve_changes: 'Aprovar alteração',
  reply: 'Resposta em caso de reprovação (opcional)',

  reason: 'Motivo',
  approved: 'Aprovado',
  uninformed: 'Não informado',

  old_value: 'Valor anterior',
  requested_value: 'Alteração solicitada',
};
