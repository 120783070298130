import { useContext } from 'react';

import { RouteContext, RouteContextType } from '~/context/route';

function useRoute(): RouteContextType {
  const context = useContext(RouteContext);

  if (!context) {
    throw new Error(`useRoute must be used within a RouteProvider`);
  }

  return context;
}

export default useRoute;
