import React, { lazy } from 'react';

import Route from './Route';

const SignIn = lazy(() => import('~/pages/auth/SignIn'));
const ForgotPassword = lazy(() => import('~/pages/auth/ForgotPassword'));

const authRoute = (
  <>
    <Route path="/" element={<SignIn />} hideFallback isPublic />

    <Route path="/forgot" element={<ForgotPassword />} hideFallback isPublic />
  </>
);

export default authRoute;
