export default {
  title: 'Visão Geral',

  cards: {
    executions: {
      running: {
        title: 'Correções em andamento',
        description: 'Total de correções que estão em andamento',
      },

      failed: {
        title: 'Falhas',
        description: 'Total de falhas durante as correções',
      },
    },

    challenges: {
      blocked: {
        title: 'Desafios bloqueados',
        description: 'Total de desafios que foram bloqueados',
      },
    },

    submissions: {
      blocked: {
        title: 'Submissões bloqueadas',
        description: 'Total de submissões que foram bloqueadas',
      },
    },
  },

  charts: {
    executions: {
      total: {
        title: 'Total de execuções',
        description: 'Porcentagem de execuções das correções',
      },

      fail_rate: {
        title: 'Taxa de falha',
        description: 'Porcentagem de falhas que ocorreram durante uma correção',
      },
    },
  },
};
