export default {
  save: 'Responder solicitação',

  approve: {
    confirm: {
      title: 'Deseja realmente aprovar esta solicitação?',
      description: 'Você irá aceitar a alteração de uma informação sensível',

      confirm: 'Sim, quero aprovar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Solicitação aprovada',
      },
    },
  },

  reply: {
    confirm: {
      title: 'Deseja realmente responder esta solicitação?',
      description: 'Você irá recusar a alteração de uma informação sensível',

      confirm: 'Sim, quero responder',
      cancel: 'Não, quero manter',

      success: {
        title: 'Solicitação respondida!',
      },
    },
  },
};
