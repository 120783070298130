import form from './form';
import list from './list';
import modals from './modals';
import statistics from './statistics';

export default {
  title: 'Aulas',

  statistics,
  list,
  modals,
  form,
};
