export default {
  title: 'Cache',

  clear: {
    title: 'Limpar cache de todos os usuários',
    subtitle:
      'Limpando dos caches as informações das contas e dashboards dos usuários novos dados serão pegos do banco de dados novamente.',
    reset: 'Resetar cache',
  },

  reset: {
    confirm: {
      title: 'Resetar cache',
      description:
        'Você tem certeza que deseja resetar o cache de todos os usuários da plataforma?',

      confirm: 'Sim, resetar',
      cancel: 'Cancelar',

      success: {
        title: 'Cache resetado com sucesso!',
        description:
          'Os dados dos usuários armazenados em cache foram apagados',
      },
    },
  },
};
