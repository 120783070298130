export default {
  save: 'Salvar dados',

  name: 'Nome completo',
  slug: 'Slug (gerado automaticamente)',

  teams: 'Selecione os times',

  cards: {
    details: 'Detalhes da conta',
    delivery_address: 'Endereço de entrega',
    billing_address: 'Endereço de cobrança',
    social: 'Redes Sociais',
    teams: 'Times',
    checkbox: 'Utilizar mesmo endereço de entrega',
  },

  address: {
    postal_code: 'CEP',
    street_name: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    city: 'Cidade',
    state: {
      label: 'Estado',
      placeholder: 'UF',
    },
  },

  new: {
    confirm: {
      title: 'Deseja realmente criar esse usuário?',
      description: 'Você irá criar um novo usuário',

      confirm: 'Sim, quero criar',
      cancel: 'Não, quero cancelar',

      success: {
        title: 'Usuário criado!',
        description: 'Você criou um novo usuário',
      },
    },
  },

  edit: {
    confirm: {
      title: 'Deseja realmente modificar esse usuário?',
      description: 'Você irá editar as informações deste usuário',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Usuário editado!',
        description: 'Você editou as informações deste usuário',
      },
    },
  },

  validations: {
    postal_code: 'O CEP está invalido',
    phone: 'O celular deve conter no máximo 11 dígitos',
    document: 'O documento deve conter no máximo 14 dígitos',
  },
};
