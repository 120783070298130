export default {
  prefix_title: 'Time / ',

  save: 'Salvar dados',

  edit: {
    title: 'Editar {teamTitle}',

    confirm: {
      title: 'Deseja realmente modificar esse time?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Time editado!',
        description: 'O time {teamTitle} foi editado com sucesso',
      },
    },
  },

  new: {
    title: 'Novo time',

    confirm: {
      title: 'Deseja realmente criar esse time?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Time criado!',
        description: 'O time {teamTitle} foi criado com sucesso',
      },
    },
  },

  title: {
    placeholder: 'Nome do time',
  },

  auto_enrollment: {
    title: 'Auto inscrição',
  },

  enroll_everyone: {
    title: 'Inscrever todos os usuários',
  },

  journeys: {
    title: 'Jornadas',
    placeholder: 'Selecionar jornadas',
  },

  discord_roles: {
    title: 'Discord roles',
    placeholder: 'Discord roles',
  },
};
