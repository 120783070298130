import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import api from '~/services/api';

interface Props {
  children?: React.ReactNode;
}

interface User {
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: Array<string>;
}

interface AuthState {
  token?: string;
  refreshToken?: string;
  user?: User;
  signed?: boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
}

export interface AuthContextType {
  user?: User;
  signed: boolean;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

function AuthProvider({ children }: Props) {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Shuttle:token');
    const refreshToken = localStorage.getItem('@Shuttle:refresh_token');
    const user = localStorage.getItem('@Shuttle:user');

    if (token && refreshToken && user) {
      return { token, refreshToken, user: JSON.parse(user) };
    }

    return {};
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@Shuttle:token');
    localStorage.removeItem('@Shuttle:refresh_token');
    localStorage.removeItem('@Shuttle:user');

    setData({});
  }, []);

  useEffect(() => {
    api.registerInterceptTokenManager(signOut);
  }, [signOut]);

  useEffect(() => {
    if (data.token) {
      api.defaults.headers.Authorization = `bearer ${data.token}`;

      api.get<User>('account').then(response => {
        const { data: userData } = response;

        if (!userData.avatar_url) {
          userData.avatar_url = `https://ui-avatars.com/api/?background=5B4699&color=fff&name=${userData?.name}`;
        }

        localStorage.setItem('@Shuttle:user', JSON.stringify(userData));

        setData(oldState => ({ ...oldState, user: userData }));
      });
    }
  }, [data.token]);

  const signIn = useCallback(async credentials => {
    const response = await api.post('admin/sessions', credentials);

    const { token, refreshToken } = response.data;

    localStorage.setItem('@Shuttle:token', token);
    localStorage.setItem('@Shuttle:refresh_token', refreshToken);

    setData({ token, refreshToken });
  }, []);

  const value = useMemo(
    () => ({
      user: data.user,
      signIn,
      signOut,
      signed: !!data.token && !!data.user,
    }),
    [signIn, signOut, data.user, data.token],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
