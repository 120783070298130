import details from './details';
import form from './form';
import list from './list';

export default {
  title: 'Usuários',

  list,
  details,
  form,
};
