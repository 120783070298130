export default {
  prefix_title: 'Grupo de Permissões / ',
  save: 'Salvar dados',

  new: {
    title: 'Novo grupo de permissões',

    confirm: {
      title: 'Deseja realmente criar esse grupo de permissões?',
      description: 'Você irá criar um novo grupo',

      confirm: 'Sim, quero criar',
      cancel: 'Não, quero cancelar',

      success: {
        title: 'Grupo criado!',
        description: 'Você criou um novo grupo de permissões',
      },
    },
  },

  edit: {
    title: 'Editar {groupName}',

    confirm: {
      title: 'Deseja realmente modificar esse grupo de permissões?',
      description: 'Você irá editar as informações deste grupo',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Grupo editado!',
        description: 'Você editou as informações do grupo de permissões',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir este grupo de permissões?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Grupo excluído!',
      },
    },
  },

  group_details: 'Detalhes do grupo',

  name: {
    placeholder: 'Content Manager',
  },

  slug: {
    placeholder: 'Content',
  },
};
