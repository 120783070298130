import dashboard from './dashboard';
import form from './form';
import list from './list';

export default {
  title: 'Notificações',

  status: {
    sent: 'Enviado',
    canceled: 'Cancelado',
    scheduled: 'Agendado',
  },

  validate: {
    has_passed_schedule: 'Você não pode agendar uma data que já passou.',
  },

  dashboard,
  list,
  form,
};
