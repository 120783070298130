export default {
  yes: 'Sim',
  no: 'Não',

  email: 'E-mail',
  password: 'Senha',
  name: 'Nome',
  title: 'Título',
  user: 'Usuário',
  status: 'Status',
  description: 'Descrição',
  text: 'Texto',
  cpf_cnpj: 'CPF/CNPJ',
  phone: 'Telefone',
  mobile_phone: 'Celular',

  value: 'Valor',
  slug: 'Slug',
  field: 'Campo',
  reply: 'Resposta',

  actions: 'Ações',

  platform: 'Plataforma',

  sent_at: 'Enviado em',
  answered_at: 'Respondido em',
  creation_date: 'Data de criação',
  publication_date: 'Data de publicação',

  my_profile: 'Meu perfil',
  logout: 'Sair do Backoffice',

  date: 'Data',
  team: 'Time',
  hour: 'Hora',

  last: '{num, plural, =1 {Último} other {Últimos}}',

  social: {
    discord: 'Discord',
    github: 'GitHub',
    linkedin: 'LinkedIn',
    twitter: 'Twitter',
  },

  format: {
    date: 'dd/MM/yyyy',
    date_and_hours: 'dd/MM/yyyy HH:mm',
  },

  errors: {
    not_allowed: 'Desculpe, você não tem permissão para acessar essa página.',
    server_error: 'Não foi possível comunicar com o servidor',
    unexpected_error: 'Ops, ocorreu algum erro!',
  },

  upload: {
    loading: 'Fazendo upload do arquivo...',
  },
};
