export default {
  new: 'Convidar',
  search: 'Buscar por nome, e-mail ou CNPJ',
  filter: 'Filtrar por status',

  empty: 'Nenhum pré-cadastro encontrado',

  created_at: 'Criado em',

  status_options: {
    opened: 'E-mail aberto',
    canceled: 'Cancelado',
    pending: 'Ag.Avaliação',
    complete: 'Completo',
    sent: 'E-mail enviado',
    sending: 'Enviando',
    error: 'Erro no envio',
    rejected: 'Recusado',
  },

  options: {
    resend: 'Reenviar convite',
    edit: 'Editar convite',
    delete: 'Excluir convite',
    accept: 'Aprovar',
    reject: 'Recusar',
  },

  resend: {
    confirm: {
      title: 'Deseja realmente reenviar o e-mail?',
      description: 'A empresa "{companyName}" receberá um novo e-mail',

      confirm: 'Sim, quero reenviar',
      cancel: 'Cancelar',

      success: {
        title: 'Reenvio de convite realizado!',
        description:
          'Um novo e-mail será enviado para a empresa "{companyName}"',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir o convite da empresa "{companyName}"?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Convite excluído!',
      },
    },
  },

  accept: {
    confirm: {
      title: 'Deseja realmente aprovar esse pré-cadastro?',
      description:
        'A empresa "{companyName}" receberá um e-mail para finalizar o pré-cadastro.',

      confirm: 'Sim, quero aprovar',
      cancel: 'Cancelar',

      success: {
        title: 'Pré-cadastro aprovado com sucesso!',
        description: 'A empresa "{companyName}" pode finalizar o pré-cadastro.',
      },
    },
  },

  reject: {
    confirm: {
      title: 'Deseja realmente recusar esse pré-cadastro?',
      description:
        'A empresa "{companyName}" receberá um e-mail informando que seu pré-cadastro não foi aprovado.',

      confirm: 'Sim, quero recusar',
      cancel: 'Cancelar',

      success: {
        title: 'Pré-cadastro recusado!',
        description:
          'A empresa "{companyName}" não pode prosseguir com o pré-cadastro.',
      },
    },
  },
};
