export default {
  new: 'Novo time',
  search: 'Buscar time',

  empty: 'Nenhum time foi encontrado',

  subscription: {
    manual: 'Manual',
    auto: 'Automático',
  },

  table_columns: {
    subscription: 'Inscrição',
    hotmart_id: 'Hotmart ID',
    members: 'Membros',
  },

  options: {
    details: 'Ver detalhes',
    edit: 'Editar cadastro',
  },
};
