import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const LessonsList = lazy(() => import('~/pages/Courses/Lessons/LessonsList'));

const coursesLessonsRoutes = (
  <Route path="/lessons" permissions={[permissions.lessons.view]}>
    <Route path="/" element={<LessonsList />} />
  </Route>
);

export default coursesLessonsRoutes;
