export default {
  title: {
    prefix: 'Listagem de Fóruns / ',

    new: 'Novo Fórum',
    edit: 'Editar Fórum',
  },

  edit: {
    title: 'Salvar alterações',

    confirm: {
      title: 'Deseja realmente modificar esse fórum?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Fórum editado!',
        description: 'O fórum {forumTitle} foi editado com sucesso',
      },
    },
  },

  new: {
    title: 'Novo Fórum',

    confirm: {
      title: 'Deseja realmente criar esse fórum?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Forum criado!',
        description: 'O fórum {forumTitle} foi criado com sucesso',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir este fórum?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Fórum excluído!',
      },
    },
  },

  fields: {
    multiplier: 'Multiplicador da gamificação',
  },

  placeholder: {
    title: 'Título do Fórum',
    multiplier: 'Insira um multiplicador',
  },

  validations: {
    min_multiplier:
      'É necessário informar um multiplicador igual ou maior que {min}',
    max_multiplier: 'Multiplicador não pode ser maior que {max}',
  },
};
