import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { v4 as uuid } from 'uuid';

import permissions from '~/utils/permissions';

import { Section } from './types';

const forumsSection: Section = {
  id: uuid(),
  title: 'forums.title',
  rootPath: 'forums',
  type: 'default',
  icon: HiOutlineChatAlt2,
  permissions: permissions.forums.view,
  pages: [
    {
      id: uuid(),
      title: 'forums.list.title',
      rootPath: '/forums',
      path: '/forums',
    },
  ],
};

export default forumsSection;
