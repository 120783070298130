export default {
  prefix_title: 'Empresas / ',

  save: {
    save: 'Alterar dados',
    send: 'Registrar e enviar e-mail',
    resend: 'Alterar dados e reenviar e-mail',
  },

  cnpj: {
    title: 'CNPJ',
    required: 'O CNPJ é obrigatório',
  },

  new: {
    title: 'Pré-cadastro',

    confirm: {
      title: 'Deseja realmente enviar esse convite?',
      description:
        'A empresa "{companyName}" receberá um e-mail para realizar o pré-cadastro na plataforma',

      confirm: 'Sim, quero enviar',
      cancel: 'Não, quero cancelar',

      success: {
        title: 'Envio de convite realizado!',
        description: 'Um e-mail será enviado para a empresa "{companyName}"',
      },
    },
  },

  edit: {
    title: 'Editar "{companyName}"',

    confirm: {
      title: 'Deseja realmente modificar esse convite?',
      description:
        'Você irá editar as informações do convite da empresa "{companyName}"',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Dados editados com sucesso!',
        description:
          'Você editou as informações do convite da empresa "{companyName}"',
      },
    },
  },

  resend: {
    confirm: {
      title: 'Deseja realmente editar e reenviar o convite?',
      description:
        'Você irá editar as informações e a empresa "{companyName}" receberá um novo e-mail',

      confirm: 'Sim, quero modificar e enviar',
      cancel: 'Cancelar',

      success: {
        title: 'Dados editados e reenvio de convite realizado!',
        description:
          'Você editou as informações e um novo e-mail será enviado para a empresa "{companyName}"',
      },
    },
  },
};
