import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 3.12rem;
    height: 3.81rem;
    animation: ${pulse} ease-in-out 3s infinite;

    path {
      fill: ${({ theme }) => theme.colors.titles};
    }
  }
`;
