export default {
  new: 'Novo changelog',
  search: 'Buscar por nome',
  status_filter: 'Filtrar por status',

  empty: 'Nenhum changelog foi encontrado',

  platform: {
    skylab: 'Skylab',
    hubble: 'Hubble',
  },

  status_options: {
    draft: 'Rascunho',
    scheduled_at: 'Agendado',
    published: 'Publicado',
  },

  options: {
    edit: 'Editar',
    delete: 'Excluir',
  },
};
