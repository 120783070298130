import { useContext, useMemo, useCallback } from 'react';

import { AuthContext, AuthContextType } from '~/context/auth';

type Permissions = Array<string> | string;

type Response = (permissions: Permissions) => boolean;

function useCan(): Response {
  const context = useContext<AuthContextType>(AuthContext);

  if (!context) {
    throw new Error(`useCan must be used within a AuthProvider`);
  }

  const { user } = context;

  const userPermissions = useMemo(() => {
    return user?.permissions;
  }, [user]);

  const can = useCallback(
    permissions => {
      if (!userPermissions) {
        return false;
      }

      const permissionsArray = Array.isArray(permissions)
        ? permissions
        : [permissions];

      const hasPermissions = permissionsArray.every(permission =>
        userPermissions.includes(permission),
      );

      return hasPermissions;
    },
    [userPermissions],
  );

  return can;
}

export default useCan;
