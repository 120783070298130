export default {
  title: {
    edit: 'Editar aula',
  },

  tabs: {
    lesson_config: 'Configurações de aula',
    lesson_material: 'Aula e Material',
    thumbnail: 'Thumbnail',
    sessions: 'Sessões',
  },

  buttons: {
    save: 'Salvar alterações',
  },

  subtitle: {
    upload_lesson: 'Envio da Aula',
    select_thumbnail: 'Selecione um thumbnail',
  },

  validations: {
    title: 'O título é obrigatório',
  },

  placeholder: {
    title: 'Digite o título da sessão',
    default_time: '00:00:00',
  },

  errors: {
    remove_sessions: 'Houve um erro ao remover sessão.',
    create_many_sessions_same_second:
      'Não é possível criar mais de uma sessão no mesmo segundo.',
  },

  sessions: {
    empty: 'O vídeo ainda está carregando. Tente novamente mais tarde.',
  },
};
