import challenges from './challenges';
import dashboard from './dashboard';
import submissions from './submissions';

export default {
  title: 'Cerbero',

  dashboard,
  submissions,
  challenges,
};
