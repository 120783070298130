export default {
  title: 'Denúncias',

  search: 'Buscar denúncias',
  filter: 'Filtrar por status',

  empty: 'Nenhuma denúncia foi encontrada',

  table_columns: {
    id: 'ID',
    date: 'Data/Hora',
    report_by: 'Reportado por',
    reported: 'Denunciado',
    zone: 'Área',
    status: 'Status',
    type: 'Tipo',
  },

  tooltip: {
    aggregation_number:
      'Foram feitas mais {aggregationNumber} denúncias sobre o mesmo comportamento',
    approved: 'Aprovado por {answeredBy} dia {answeredAt}',
    rejected: 'Rejeitado por {answeredBy} dia {answeredAt}',
  },

  options: {
    details: 'Visualizar denúncia',
  },
};
