export default {
  new: 'Novo usuário',
  empty: 'Nenhum usuário encontrado',

  search: 'Buscar usuário',
  team_filter: 'Filtrar por time',

  table_columns: {
    teams: 'Times',
  },

  options: {
    details: 'Ver detalhes',
    edit: 'Editar cadastro',
  },
};
