export default {
  title: 'dark',

  colors: {
    primary: '#8257E6',
    greenseat: '#33FF99',
    green: '#04D361',
    greenlow: '#11251C',
    errolow: '#3B2228',
    purplelow: '#2D264A',
    red: '#E83F5B',
    orange: '#FD951F',
    white: '#FFFFFF',
    background: '#121214',
    mainShape: '#202024',
    shape18: '#29292E',
    shape22: '#323238',
    shape26: '#3C3C42',
    titles: '#E1E1E6',
    text: '#A8A8B3',
    supportText: '#737380',
    tooltipBackground: '#121214',
    helperBackground: '#29292E',
    inputsIcons: '#41414D',
    sideBarIcon: '#C9B2FF',
    overlay: '#00000033',
  },
};
