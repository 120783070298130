import React from 'react';

import Route from '../Route';
import changelogsRoutes from './changelogs.routes';
import companiesRoutes from './companies.routes';
import featureFlagsRoutes from './feature_flags.routes';
import permissionsGroupRoutes from './permissions-group.routes';
import sensitiveInformationRoutes from './sensitive-information.routes';
import teamsRoutes from './teams.routes';
import technologiesRoutes from './technologies.routes';
import usersRoutes from './users.routes';

const registrationsRoutes = (
  <Route path="/registrations">
    {changelogsRoutes}
    {companiesRoutes}
    {permissionsGroupRoutes}
    {sensitiveInformationRoutes}
    {teamsRoutes}
    {technologiesRoutes}
    {usersRoutes}
    {featureFlagsRoutes}
  </Route>
);

export default registrationsRoutes;
