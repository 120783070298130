import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const SensitiveInformationList = lazy(
  () =>
    import(
      '~/pages/Registrations/SensitiveInformation/SensitiveInformationList'
    ),
);
const SensitiveInformationReply = lazy(
  () =>
    import(
      '~/pages/Registrations/SensitiveInformation/SensitiveInformationReply'
    ),
);
const registrationsSensitiveInformationRoutes = (
  <Route
    path="/sensitive-information"
    permissions={[permissions.sensitive_information.view]}
  >
    <Route path="/" element={<SensitiveInformationList />} />

    <Route
      path="/:id"
      element={<SensitiveInformationReply />}
      permissions={[permissions.sensitive_information.reply]}
    />
  </Route>
);

export default registrationsSensitiveInformationRoutes;
