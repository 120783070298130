import { v4 as uuid } from 'uuid';

import { ReactComponent as ExpertsLogo } from '~/assets/svgs/rocket_experts.svg';
import permissions from '~/utils/permissions';

import { Section } from './types';

const expertsSection: Section = {
  id: uuid(),
  title: 'experts.title',
  rootPath: 'experts',
  type: 'default',
  icon: ExpertsLogo,
  pages: [
    {
      id: uuid(),
      title: 'experts.creators.title',
      rootPath: '/creators',
      path: '/experts/creators',
      permissions: permissions.users.view,
    },
    {
      id: uuid(),
      title: 'experts.lessons.title',
      rootPath: '/lessons',
      path: '/experts/lessons',
      permissions: permissions.lessons.view,
    },
  ],
};

export default expertsSection;
