export default {
  title: 'Listagem de fóruns',

  new: 'Novo fórum',
  search: 'Buscar fórum',

  empty: 'Nenhum fórum foi encontrado',

  table_columns: {
    title: 'Título',
    multiplier: 'Multiplicador',
    slug: 'Slug',
    actions: 'Ações',
  },

  options: {
    edit: 'Editar fórum',
    remove: 'Excluir fórum',
  },

  remove: {
    confirm: {
      title: 'Deseja realmente excluir esse fórum?',

      confirm: 'Sim, quero excluí-lo',
      cancel: 'Cancelar',

      success: {
        title: 'Forum excluído!',
        description: 'O fórum {forumTitle} foi exclído com sucesso',
      },
    },
  },
};
