export default {
  title: 'Aulas',
  new: 'Nova Aula',
  search: 'Buscar aulas...',

  empty: 'Nenhuma aula foi encontrada',

  table_columns: {
    title: 'Aula',
    journey: 'Jornada atrelada',
    type_lesson: 'Tipo de aula',
  },

  options: {
    edit: 'Editar aula',
    remove: 'Excluir aula',
  },
};
