import details from './details';
import form from './form';
import list from './list';

export default {
  title: 'Times',

  details,
  list,
  form,
};
