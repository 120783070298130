import { FiPlayCircle } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';

import permissions from '~/utils/permissions';

import { Section } from './types';

const coursesSection: Section = {
  id: uuid(),
  title: 'courses.title',
  rootPath: 'courses',
  type: 'default',
  icon: FiPlayCircle,
  pages: [
    {
      id: uuid(),
      title: 'courses.lessons.list.title',
      rootPath: '/lessons',
      path: '/courses/lessons',
      permissions: permissions.lessons.edit,
    },
    {
      id: uuid(),
      title: 'courses.journeys.list.title',
      rootPath: '/journeys',
      path: '/courses/journeys',
      permissions: permissions.journeys.edit,
    },
  ],
};

export default coursesSection;
