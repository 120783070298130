import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const FeatureFlagsList = lazy(
  () => import('~/pages/Registrations/FeatureFlags/FeatureFlagsList'),
);
const FeatureFlagsForm = lazy(
  () => import('~/pages/Registrations/FeatureFlags/FeatureFlagsForm'),
);

const registrationsFeatureFlagsRoutes = (
  <Route path="/feature_flags" permissions={[permissions.feature_flags.view]}>
    <Route path="/" element={<FeatureFlagsList />} />

    <Route
      path="/create"
      element={<FeatureFlagsForm />}
      permissions={[permissions.feature_flags.edit]}
    />

    <Route
      path="/:id/edit"
      element={<FeatureFlagsForm />}
      permissions={[permissions.feature_flags.edit]}
    />
  </Route>
);

export default registrationsFeatureFlagsRoutes;
