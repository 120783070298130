import React from 'react';

import { ReactComponent as RocketImage } from '~/assets/svgs/rocket.svg';

import { Container } from './styles';

interface Props {
  isActive: boolean;
}

function Loading({ isActive }: Props) {
  if (isActive) {
    return (
      <Container>
        <RocketImage />
      </Container>
    );
  }

  return null;
}

export default Loading;
