export default {
  title: {
    prefix: 'Estatísticas - Aula {lessonTitle}',
  },

  empty: {
    impressions: 'Parece que ainda não temos impressões...',
    open_feedbacks: 'Parece que ainda não temos feedbacks abertos...',
  },

  likes: 'Likes',
  dislikes: 'Dislikes',

  evolution: 'Evolução nos últimos 7 dias',

  reproductions: 'Reproduções totais',
  average_time: 'Média tempo assistido',
  relevance: 'Relev. comunidade',
  permanence: 'Permanência na aula',

  impressions: {
    catalog: 'Catálogo',
    history: 'Histórico',
    profile: 'Perfil',
    playlist: 'Playlist',
    search: 'Busca',
    tag: 'Tags',
    my_tags: 'Minhas tags',
    player_playlist: 'Lista',
  },

  feedbacks: {
    like: {
      content: 'A aula contém o aprendizado que eu esperava',
      approach: 'A forma que o tema foi abordado é satisfatória',
      content_organization: 'O desenvolvimento do conteúdo foi bem organizado',
      instructor_knowledge:
        'Quem conduziu a aula demonstra ter conhecimento suficiente sobre o tema abordado',
    },
    dislike: {
      content:
        'O conteúdo do vídeo não continha as informações que eu esperava',
      approach: 'A abordagem do tema feita pelo instrutor não foi boa',
      content_organization: 'A estrutura do conteúdo não está bem organizada',
      instructor_knowledge:
        'O instrutor não possui conhecimento suficiente sobre o assunto abordado',
    },
  },

  cards: {
    reactions: 'Reações',
    feedbacks: {
      title: 'Feedbacks',
      content: 'Conteúdo',
      approach: 'Abordagem',
      structure: 'Estrutura',
      knowledge: 'Conhecimento do instrutor',
    },
    impressions: 'Impressões por área',
    open_feedbacks: 'Feedbacks abertos',
  },

  tooltips: {
    reactions: 'Comparativo de likes e dislikes no vídeos.',
    feedbacks: 'Feedbacks de melhoria sobre a aula.',
    impressions:
      'Quantidade de vezes que o conteúdo foi exibido em determinada área.',
    open_feedbacks: 'Feedbacks descritos pelos usuários.',
    reproductions: 'Visualizações acumuladas desta aula desde a ultima semana.',
    average_time: 'Média do tempo assistido desta aula desde a ultima semana.',
    relevance: 'Relevância do seu conteúdo para a comunidade.',
    permanence: 'Porcentagem média de permanência em sua aula.',
  },

  chart: {
    average_description:
      'Calculo aproximado devido a falta de informações para obter exatidão.',
    evolution_description:
      'Porcentagem em relação a este mesmo dia na semana anterior.',
  },
};
