import { createGlobalStyle } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import zindex from './zindex';
import './lib/react-toastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Roboto', sans-serif;

    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  input, textarea {
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Roboto', sans-serif;
  }

  label {
    font-size: 0.88rem;
    color: ${({ theme }) => theme.colors.text};

    margin-bottom: 0.5rem;
  }

  button {
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  h1 {
    font-size: 1.5rem;
    padding-bottom: 1.88rem;
    color: ${({ theme }) => theme.colors.titles};
  }

  & {
    .Toastify__toast-container {
      z-index: ${zindex.toast};
    }

    .Toastify__progress-bar {
      z-index: ${zindex.toast};
    }
  }
`;
