import { useContext } from 'react';

import { IntlContext, IntlContextType } from '~/context/intl';

function useIntl(): IntlContextType {
  const context = useContext(IntlContext);

  if (!context) {
    throw new Error(`useIntl must be used within a IntlProvider`);
  }

  return context;
}

export default useIntl;
