import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from './Route';

const Dashboard = lazy(() => import('~/pages/Notifications/Dashboard'));
const List = lazy(() => import('~/pages/Notifications/List'));
const Form = lazy(() => import('~/pages/Notifications/Form'));

const notificationsRoutes = (
  <Route path="/notifications" permissions={[permissions.notifications.view]}>
    <Route path="/" element={<List />} />

    <Route path="/dashboard" element={<Dashboard />} />

    <Route
      path="/create"
      permissions={[permissions.notifications.edit]}
      element={<Form />}
    />
    <Route
      path="/:id/edit"
      permissions={[permissions.notifications.edit]}
      element={<Form />}
    />
  </Route>
);

export default notificationsRoutes;
