import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const JourneysList = lazy(
  () => import('~/pages/Courses/Journeys/JourneysList'),
);
const JourneysForm = lazy(
  () => import('~/pages/Courses/Journeys/JourneysForm'),
);

const coursesJourneysRoutes = (
  <Route path="/journeys">
    <Route
      path="/"
      permissions={[permissions.journeys.edit]}
      element={<JourneysList />}
    />

    <Route
      path="/:id/edit"
      permissions={[permissions.journeys.edit]}
      element={<JourneysForm />}
    />
  </Route>
);

export default coursesJourneysRoutes;
