export default {
  button: {
    back: 'Voltar',
    go_profile: 'Ir para o perfil',
  },

  title: {
    prefix: '{personName}',
    suffix_reported: 'Denúncias recebidas',
    suffix_report_by: 'Denúncias realizadas',
  },
};
