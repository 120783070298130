export default {
  save: 'Salvar dados',

  card: {
    details: 'Detalhes da conta',
  },

  groups: {
    title: 'Grupos',
    placeholder: 'Nenhum',
  },

  error: 'Parece que você ainda não criou um usuário!',

  edit: {
    confirm: {
      title: 'Deseja realmente modificar as permissões deste usuário?',
      description: 'Você irá editar as permissões deste usuário',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Permissões editadas!',
        description: 'Você editou as permissões deste usuário',
      },
    },
  },
};
