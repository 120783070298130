import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from '../Route';

const TechnologiesList = lazy(
  () => import('~/pages/Registrations/Technologies/TechnologiesList'),
);
const TechnologiesForm = lazy(
  () => import('~/pages/Registrations/Technologies/TechnologiesForm'),
);

const registrationsTechnologiesRoutes = (
  <Route path="/technologies">
    <Route path="/" element={<TechnologiesList />} />

    <Route
      path="/create"
      permissions={[permissions.technologies.edit]}
      element={<TechnologiesForm />}
    />

    <Route
      path="/:id/edit"
      permissions={[permissions.technologies.edit]}
      element={<TechnologiesForm />}
    />
  </Route>
);

export default registrationsTechnologiesRoutes;
