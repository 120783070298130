export default {
  title: 'Desafios',

  empty: 'Nenhum desafio encontrado',

  status: {
    blocked: 'Bloqueados',
    unblocked: 'Desbloqueados',
  },

  options: {
    block: 'Bloquear',
    unblock: 'Desbloquear',
  },

  block: {
    confirm: {
      title: 'Você deseja bloquear esse desafio?',
      description: 'Você poderá desbloquear esse desafio posteriormente.',

      confirm: 'Sim, quero bloquear',
      cancel: 'Não, quero manter',

      success: {
        title: 'Desafio bloqueado!',
        description: 'Esse desafio não poderá receber respostas.',
      },
    },
  },

  unblock: {
    confirm: {
      title: 'Você deseja desbloquear esse desafio?',
      description: 'Você poderá bloquear esse desafio posteriormente.',

      confirm: 'Sim, quero desbloquear',
      cancel: 'Não, quero manter',

      success: {
        title: 'Desafio desbloqueado!',
        description: 'Esse desafio está aberto a receber novas respostas.',
      },
    },
  },
};
