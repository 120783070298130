import form from './form';
import list from './list';
import reply from './reply';

export default {
  title: 'Alteração de dados',

  list,
  reply,
  form,
};
