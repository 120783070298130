export default {
  title: 'Aulas',

  new: 'Nova Aula',
  search: 'Buscar por título ou creator',
  status_filter: 'Filtrar por status',

  empty: 'Nenhuma aula foi encontrada',

  status_options: {
    scheduled: 'Agendado',
    published: 'Publicada',
  },

  table_columns: {
    title: 'Título da aula',
    creator: 'Creator',
    publication: 'Publicação',
    views: 'Visualizações',
    likes: 'Likes',
    dislikes: 'Dislikes',
    status: 'Status',
  },

  options: {
    feature: 'Destacar aula',
    publish: 'Publicar aula',
    edit: 'Editar',
    unsubscribe: 'Cancelar publicação',
    statistics: 'Estatísticas',
  },

  remove: {
    confirm: {
      title: 'Deseja realmente excluir essa aula?',

      confirm: 'Sim, quero excluí-la',
      cancel: 'Cancelar',

      success: {
        title: 'Aula excluída!',
        description: 'A aula {lessonTitle} foi excluída com sucesso',
      },
    },
  },

  status_cell: {
    scheduled: 'Agendado',
    published: 'Publicada',
    processing: 'Processando',
  },
};
