export default {
  modal_step_one: {
    title: 'Nova Aula - Passo 1: Configuração da Aula',
    edit_title: 'Edição de Aula: Configuração da Aula',
    subtitle_one: 'Dados da Aula',
  },

  modal_step_two: {
    title: 'Nova Aula - Passo 2: Envio da aula e Material de Apoio',
    edit_title: 'Edição de Aula: Envio da aula e Material de Apoio',
    subtitle_one: '',
    subtitle_two: 'Material de apoio',
  },

  modal_step_three: {
    title: 'Nova Aula - Passo 3: Thumbnail',
    edit_title: 'Edição de Aula: Thumbnail',
    subtitle_one: 'Selecione um Thumbnail',
  },

  modal_step_four: {
    title: 'Nova Aula - Passo 4: Configurações de Sessões (Opcional)',
    subtitle_one: 'Sessões',
    empty:
      'O vídeo ainda está carregando. Insira as sessões editando a aula na aba "Sessões".',
  },

  fields: {
    title: 'Título da Aula',
    slug: 'Slug da Aula',
    technologies: 'Tecnologias',
    tags: 'Tags da Aula',
    creator: 'Creator',
    release_at: 'Data de liberação',
    release_now: 'Publicar agora',
    description: 'Descrição da aula (markdown)',
    short_description:
      'Breve descrição (Descrição reduzida, utilizada em áreas especificas com menos caracteres)',
    material_title: 'Título',
    link: 'Link',
    platform: 'Plataforma de vídeo',
    video_id: 'ID do vídeo',
  },

  placeholder: {
    title: 'Digite o título da aula',
    slug: 'Gerado automaticamente',
    technologies: 'Ex: React, React Native',
    no_options_technology: 'Crie uma tecnologia',
    tags: 'Ex: #Tag1, #Tag2',
    no_options_tag: 'Crie uma tag',
    creator: 'Ex: Diego Fernandes',
    release_at: 'Ex: XX/XX/XXXX',
    material_title: 'Digite aqui o título do material',
    link: 'Ex: https://rocketseat.com/arquivo.pdf',
    jupiter_id: 'Digite o ID do vídeo',
    video_id: 'Ex: 34342342',
    thumbnail: 'Arraste um arquivo ou clique para carregar',
  },

  buttons: {
    back: 'Voltar',
    cancel: 'Cancelar',
    submit: 'Cadastrar Aula',
    edit: 'Editar Aula',
    next_step: 'Próximo passo',
    add: 'Add Material',
    save: 'Salvar',
  },

  validations: {
    title: 'O título é obrigatório',
    tags: 'Tag é obrigatório',
    creator: 'Criador é obrigatório',
    release_at: 'Data de liberação é obrigatório',
    description: 'Descrição é obrigatório',
    platform: 'Plataforma é obrigatório',
    resource: 'ID do vídeo é obrigatório',
    material_title: 'Título do material é obrigatório',
    link: 'Link é obrigatório',
    wrong_link_format: 'Insira um link válido.',
    thumbnail: 'Thumbnail é obrigatório',
  },

  errors: {
    sessions: 'Houve um erro ao enviar as sessões.',
  },

  edit: {
    title: 'Salvar alterações',

    confirm: {
      title: 'Deseja realmente modificar essa aula?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Aula editada!',
        description: 'A aula {lessonTitle} foi editada com sucesso',
      },
    },
  },

  new: {
    title: 'Nova Aula',

    confirm: {
      title: 'Deseja realmente criar essa aula?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Aula criada!',
        description: 'A aula {lessonTitle} foi criada com sucesso',
      },
    },
  },

  publish: {
    title: 'Publicar aula',

    confirm: {
      title: 'Deseja realmente publicar essa aula?',

      confirm: 'Sim, quero publicar',
      cancel: 'Cancelar',

      success: {
        title: 'Aula publicada!',
        description: 'A aula {lessonTitle} foi publicada com sucesso',
      },
    },
  },

  featured: {
    title: 'Destacar aula',

    confirm: {
      title: 'Deseja realmente destacar essa aula?',
      description: 'A aula aparecerá no topo do feed dos experts',

      confirm: 'Sim, quero destacar',
      cancel: 'Cancelar',

      success: {
        title: 'Aula destacada!',
        description: 'A aula {lessonTitle} foi destacada com sucesso',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir essa aula?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Aula excluída!',
      },
    },
  },

  close_modal: {
    title: 'Cancelar alterações',
    description:
      'Tem certeza que deseja voltar? Os dados não salvos serão perdidos.',
  },
};
