import coursesSection from './courses';
import expertsSection from './experts';
import forumsSection from './forums';
import notificationsSection from './notifications';
import registrationsSection from './registrations';
import reportsSection from './reports';
import settingsSection from './settings';

export default [
  expertsSection,
  notificationsSection,
  registrationsSection,
  forumsSection,
  reportsSection,
  coursesSection,
  settingsSection,
];
