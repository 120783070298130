import React, { lazy } from 'react';

import permissions from '~/utils/permissions';

import Route from './Route';

const Dashboard = lazy(() => import('~/pages/Cerbero/Dashboard'));
const ChallengesList = lazy(() => import('~/pages/Cerbero/ChallengesList'));
const SubmissionsList = lazy(() => import('~/pages/Cerbero/SubmissionsList'));

const cerberoRoutes = (
  <Route path="/cerbero" permissions={[permissions.cerbero.view]}>
    <Route path="/dashboard" element={<Dashboard />} />

    <Route
      path="/challenges"
      permissions={[permissions.challenges.view]}
      element={<ChallengesList />}
    />

    <Route
      path="/submissions"
      permissions={[permissions.cerbero.view_executions]}
      element={<SubmissionsList />}
    />
  </Route>
);

export default cerberoRoutes;
