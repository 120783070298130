import access_history from './access_history';
import emails_history from './emails_history';
import personal from './personal';
import purchases from './purchases';

export default {
  title: 'Detalhes de',
  edit: 'Editar usuário',

  personal,
  purchases,
  access_history,
  emails_history,
};
