import changelogs from './changelogs';
import companies from './companies';
import feature_flags from './feature_flags';
import permissions_group from './permissions_group';
import sensitive_information from './sensitive_information';
import teams from './teams';
import technologies from './technologies';
import users from './users';

export default {
  title: 'Cadastros',

  teams,
  users,
  sensitive_information,
  changelogs,
  permissions_group,
  technologies,
  companies,
  feature_flags,
};
