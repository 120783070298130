export default {
  email: {
    required: 'O e-mail é obrigatório',
    email: 'Digite um e-mail válido',
  },

  password: {
    required: 'A senha é obrigatória',
    min: 'A senha deve ter no mínimo {min} caracteres',
  },

  title: {
    required: 'O título é obrigatório',
  },

  name: {
    required: 'O nome é obrigatório',
  },

  description: {
    required: 'A descrição é obrigatória',
    max: 'A descrição deve ter no máximo {maxLengthDescription} caracteres',
  },

  text: {
    required: 'O texto é obrigatório',
  },

  link: {
    url: 'Digite um link válido',
  },

  slug: {
    required: 'O slug é obrigatório',
    matches: 'Não é possível ter espaços nem caracteres especiais',
  },

  image: {
    required: 'A imagem é obrigatória',
  },

  justification: {
    required: 'A justificativa é obrigatória',
  },
};
