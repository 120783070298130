export default {
  title: 'Histórico de e-mails',

  empty: 'Nenhum e-mail encontrado',
  current_email: 'E-mail atual',

  table_columns: {
    updated_at: 'Atualizado em',
  },
};
