export default {
  notifications: {
    view: 'view_notifications',
    edit: 'edit_notifications',
  },

  users: {
    view: 'view_users',
    edit: 'edit_users',
    edit_roles: 'edit_users-roles',
    edit_teams: 'edit_users-teams',
    export: 'export_users',
  },

  teams: {
    view: 'view_teams',
    edit: 'edit_teams',
  },

  forums: {
    view: 'view_forums',
    edit: 'edit_forums',
  },

  purchases: {
    view: 'view_purchases',
    edit: 'edit_purchases',
  },

  cerbero: {
    view: 'view_cerbero',
    view_executions: 'view_cerbero_executions',
    block_challenge: 'block_cerbero_challenges',
    block_executions: 'block_cerbero_executions',
    run_executions: 'run_cerbero_correction',
  },

  challenges: {
    view: 'view_challenges',
    edit: 'edit_challenges',
  },

  sensitive_information: {
    view: 'view_sensitive-information',
    reply: 'reply_sensitive-information',
  },

  changelogs: {
    view: 'view_changelogs',
    edit: 'edit_changelogs',
  },

  permissions_group: {
    view: 'view_roles',
    edit: 'edit_roles',
  },

  technologies: {
    edit: 'edit_technologies',
  },

  lessons: {
    view: 'edit_lessons',
    edit: 'edit_lessons',
  },

  companies_registrations: {
    view: 'view_companies',
    edit: 'edit_companies',
  },

  reports: {
    view: 'view_reports',
    edit: 'edit_reports',
  },

  journeys: {
    edit: 'edit_journeys',
  },

  feature_flags: {
    view: 'view_feature_flags',
    edit: 'edit_feature_flags',
  },

  creator: 'can_create_content',
};
