export default {
  prefix_title: 'Feature Flags / ',

  title: {
    new: 'Nova Flag',
    edit: 'Editar {title}',
  },

  placeholder: {
    title: 'Digite o título da flag',
    key: 'Ex: feature-flag',
  },

  fields: {
    key: 'Chave',
  },

  active_label: 'Ativo',
  inactive_label: 'Inativo',

  edit: {
    title: 'Editar',

    confirm: {
      title: 'Deseja realmente modificar essa feature?',

      confirm: 'Sim, quero modificar',
      cancel: 'Não, quero manter',

      success: {
        title: 'Feature flag editada!',
        description: 'A feature "{title}" foi editada com sucesso',
      },
    },
  },

  new: {
    title: 'Criar',

    confirm: {
      title: 'Deseja realmente criar essa feature?',

      confirm: 'Sim, quero criar',
      cancel: 'Cancelar',

      success: {
        title: 'Feature flag criada!',
        description: 'A feature "{title}" foi criada com sucesso',
      },
    },
  },

  delete: {
    confirm: {
      title: 'Deseja realmente excluir esta flag?',
      description: 'Esta ação não poderá ser desfeita',

      confirm: 'Sim, quero excluir',
      cancel: 'Cancelar',

      success: {
        title: 'Feature flag excluída!',
      },
    },
  },
};
