export default {
  title: 'Detalhes da submissão',

  template: 'Template: {url}',
  implementation: 'Implementação: {url}',

  format: {
    created_at: "dd 'de' MMMM 'de' yyyy 'às' HH:mm:ss",
  },
};
