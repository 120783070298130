import React, { Suspense, lazy } from 'react';
import { Routes as RoutesWrapper } from 'react-router-dom';

import authRoutes from './auth.routes';
import cerberoRoutes from './cerbero.routes';
import coursesRoutes from './courses';
import expertsRoutes from './experts';
import forumsRoutes from './forums.routes';
import notificationsRoutes from './notifications.routes';
import registrationsRoutes from './registrations';
import reportsRoutes from './reports.routes';
import Route from './Route';
import settingsRoutes from './settings.routes';

const AuthLayout = lazy(() => import('~/pages/_layouts/auth'));
const DefaultLayout = lazy(() => import('~/pages/_layouts/default'));

const NotFound = lazy(() => import('~/pages/NotFound'));
const Home = lazy(() => import('~/pages/Home'));

function Routes() {
  return (
    <Suspense fallback={null}>
      <RoutesWrapper>
        <Route element={<AuthLayout />} isPublic>
          {authRoutes}
        </Route>

        <Route element={<DefaultLayout />}>
          <Route path="/home" element={<Home />} />
          {expertsRoutes}

          {notificationsRoutes}

          {registrationsRoutes}

          {cerberoRoutes}

          {forumsRoutes}

          {reportsRoutes}

          {expertsRoutes}

          {coursesRoutes}

          {settingsRoutes}
        </Route>

        <Route path="*" element={<NotFound />} blockRedirect isPublic />
      </RoutesWrapper>
    </Suspense>
  );
}

export default Routes;
