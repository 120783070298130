/* eslint-disable react/jsx-wrap-multilines */
import React, { Suspense, memo } from 'react';
import { RouteProps } from 'react-router';
import { Route as ReactRoute, useLocation, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loading from '~/components/Loading';

import { DEFAULT_PATH } from '~/context/route';
import useAuth from '~/hooks/useAuth';
import useCan from '~/hooks/useCan';
import useIntl from '~/hooks/useIntl';
import useRoute from '~/hooks/useRoute';
import EmptyLayout from '~/pages/_layouts/emptyContainer';

interface Props extends RouteProps {
  isPublic?: boolean;
  permissions?: Array<string>;
  hideFallback?: boolean;
  element?: React.ReactElement;
  blockRedirect?: boolean;
}

function Route({
  element = <EmptyLayout />,
  permissions,
  isPublic,
  hideFallback = false,
  blockRedirect = false,
  ...rest
}: Props) {
  const { signed } = useAuth();
  const { redirectTo } = useRoute();
  const { pathname, search } = useLocation();
  const { translate } = useIntl();
  const can = useCan();

  if (!signed && !isPublic) {
    return <Navigate to="/" state={{ from: `/${pathname}${search}` }} />;
  }

  if (!!permissions && !can(permissions)) {
    toast.error(translate('common.errors.not_allowed'));

    return <Navigate to={DEFAULT_PATH} />;
  }

  if (signed && isPublic && !blockRedirect) {
    return redirectTo ? (
      <Navigate to={redirectTo} />
    ) : (
      <Navigate to={DEFAULT_PATH} />
    );
  }

  return (
    <ReactRoute
      {...rest}
      element={
        <Suspense fallback={<Loading isActive={!hideFallback} />}>
          {element}
        </Suspense>
      }
    />
  );
}

export default memo(Route);
