import main from './main';
import permissions from './permissions';

export default {
  tab_bar: {
    principal: 'Principal',
    groups_and_permissions: 'Grupos e permissões',
    purchases: 'Compras',
  },

  new: {
    title: 'Novo usuário',
  },

  edit: {
    title: 'Editar {userName}',
  },

  prefix_title: 'Usuários / ',

  main,
  permissions,
};
