import React from 'react';
import { ToastContainer } from 'react-toastify';

import useIntl from '~/hooks/useIntl';

import Routes from './routes';
import GlobalStyle from './styles/global';

function App() {
  const { loading } = useIntl();

  if (loading) {
    return null;
  }

  return (
    <>
      <GlobalStyle />

      <Routes />

      <ToastContainer />
    </>
  );
}

export default App;
