import { Locale as DateFnsLocale } from 'date-fns';
import fnsPtBR from 'date-fns/locale/pt-BR';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import intl from 'react-intl-universal';

import * as pt from '~/locales/pt';
import api from '~/services/api';

type IntlLocale = { [key: string]: any };
type FnsLocale = { [key: string]: DateFnsLocale };

const intlLocales: IntlLocale = {
  'pt-BR': pt,
};

const fnsLocales: FnsLocale = {
  'pt-BR': fnsPtBR,
};

export interface IntlContextType {
  currentLocale: string;
  currentFnsLocale: DateFnsLocale;
  setCurrentLocale: Dispatch<SetStateAction<string>>;
  loading: boolean;
  translate: (key: string, variables?: any) => string;
}

interface Props {
  children?: React.ReactNode;
}

export const IntlContext = createContext<IntlContextType>(
  {} as IntlContextType,
);

function IntlProvider({ children }: Props) {
  const [currentLocale, setCurrentLocale] = useState(() => {
    const persistedLocale = localStorage.getItem('@Shuttle:locale');

    return persistedLocale || 'pt-BR';
  });

  const [loading, setLoading] = useState(true);

  const currentFnsLocale = useMemo(() => {
    return fnsLocales[currentLocale];
  }, [currentLocale]);

  useEffect(() => {
    api.defaults.headers['Accept-Language'] = currentLocale;
    setLoading(true);

    intl
      .init({
        currentLocale,
        locales: intlLocales,
        fallbackLocale: 'pt-BR',
      })
      .catch(err => {
        console.log('INTL_INIT_ERROR', err);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale]);

  useEffect(() => {
    localStorage.setItem('@Shuttle:locale', currentLocale);
  }, [currentLocale]);

  const translate = useCallback((key: string, variables?: any): string => {
    return variables ? intl.get(key, variables) : intl.get(key);
  }, []);

  return (
    <IntlContext.Provider
      value={{
        currentLocale,
        currentFnsLocale,
        setCurrentLocale,
        loading,
        translate,
      }}
    >
      {children}
    </IntlContext.Provider>
  );
}

export default IntlProvider;
