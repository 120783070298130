export default {
  search: 'Buscar solicitação',
  status_filter: 'Filtrar por status',

  empty: 'Nenhuma solicitação foi encontrada',

  status_options: {
    opened: 'Em aberto',
    answered: 'Respondido',
  },

  options: {
    reply: 'Responder solicitação',
    details: 'Ver detalhes',
  },
};
