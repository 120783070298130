import form from './form';
import list from './list';
import modal from './modal';

export default {
  title: 'Jornadas',

  list,
  form,
  modal,
};
