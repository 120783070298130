export default {
  title: 'Visão Geral',

  cards: {
    total: {
      title: 'Totais',
      description: 'Notificações totais cadastradas',
    },

    scheduled: {
      title: 'Agendadas',
      description: 'Notificações totais agendadas',
    },

    sent: {
      title: 'Enviadas',
      description: 'Notificações totais enviadas',
    },

    reach: {
      title: 'Contatos',
      description: 'Total de contatos cadastrados',
    },
  },

  charts: {
    opening_rate: {
      title: 'Taxa de abertura',
      description: 'Porcentagem de abertura das notificações totais',
    },

    clicks: {
      title: 'Links clicks',
      description: 'Total de links clicados',
    },

    relevance: {
      title: 'Relevância',
      description: 'Relevância média das notificações',
    },
  },
};
